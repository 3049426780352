.container {
  margin-top: 20px;
}

#date {
  width: 150px;
  outline: none;
  border: 1px solid #aaa;
  padding: 6px 28px;
  color: #aaa;
}

.date-container {
  position: relative;
}

.date-icon {
  position: absolute;
  top: 15px;
  right: 10px;
  /* pointer-events: none; */
  cursor: pointer;
  color: #aaa;
}
