.card-size {
  min-height: 470px;
}

/* For all pages */
.all-page-container {
  height: 100%;
  max-width: 700px;
  max-height: 500px;
  overflow: auto;
}

.ol-list-container {
  height: 100%;
  /* max-width: 700px; */
  max-height: 500px;
  overflow: auto;
}
